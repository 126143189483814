import * as React from "react";
import "../assets/sass/style.scss";

import Seo from "../blocks/seo/Seo";

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleHome from "../blocks/page-title/PageTitleHome";
import BlockProjects from "../blocks/projects/BlockProjects";
import BlockContact from "../blocks/contact/BlockContact";
import WhatIDo from "../blocks/about/WhatIDo";
import ClientList from "../blocks/clients/ClientList";
// import BlockBlog from "../blocks/blog/BlockBlog";

const IndexPage = () => {
  return (
    <div>
      <Seo
        title=""
        bodyAttributes={{
          class: "home bg-fixed bg-line",
        }}
      />

      <Header />

      <main id="main" className="site-main">
        <PageTitleHome />

        <ClientList />

        <BlockProjects />

        <div className="wrapper">
          <WhatIDo showTechBox={true} showMoreButton={true} />
        </div>

        {/* <BlockBlog /> */}

        <BlockContact goalId="YDH3OE4V" />
      </main>
      <Footer />
    </div>
  );
};

export default IndexPage;
