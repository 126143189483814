import React from "react";
import { Link } from "gatsby";
import Gallery from "../gallery/Gallery";

const BlockProjects = () => {
  return (
    <section id="my-projects" className="block spacer m-top-lg">
      <div className="wrapper">
        <h2>
          <Link to="/projects" className="transform-scale-h">
            My <span className="line">Projects</span>
          </Link>
        </h2>
      </div>

      <Gallery seeMore={true} paddingBottomClass="" />
    </section>
  );
};

export default BlockProjects;
