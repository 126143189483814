import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import PlaceholderImage from "../../assets/img/placeholder/637x637.jpg";

export default function Gallery2(props) {
  const data = useStaticQuery(graphql`
    query ProjectOverviewQuery {
      allContentfulProject(sort: { fields: order }) {
        nodes {
          title
          featured
          slug
          category {
            name
            filter
          }
          coverImage {
            gatsbyImageData(placeholder: BLURRED)
          }
          order
        }
      }
      allContentfulProjectCategory(sort: { fields: order }) {
        nodes {
          name
          filter
          order
        }
      }
    }
  `);
  const getAllItems = () => {
    if (props.seeMore) {
      return data.allContentfulProject.nodes.filter((item) => item.featured);
    }
    return data.allContentfulProject.nodes;
  };
  const allItems = getAllItems();
  const [items, setItems] = useState(allItems);
  const [selectedCategory, setSelectedCategory] = useState("*");
  const projectCategories = data.allContentfulProjectCategory.nodes;

  const onFilterChange = (filter) => {
    setSelectedCategory(filter);

    // filter projects for current filter
    if (filter === "*") {
      setItems(allItems);
    } else {
      let newItems = [];
      allItems.forEach((item) => {
        const categoryArray = item.category.reduce(
          (prevValue, curValue) => [...prevValue, curValue.filter],
          []
        );
        if (categoryArray.includes(filter)) {
          newItems.push(item);
        }
      });
      setItems(newItems);
    }
  };

  return (
    <div className="gallery">
      {props.seeMore ? (
        ""
      ) : (
        <div className="wrapper">
          <ul className="gallery-filter list-unstyled no-space">
            {projectCategories &&
              projectCategories.map((item, key) => (
                <React.Fragment key={item.name}>
                  <li>
                    <button
                      className={
                        "btn btn-link transform-scale-h click" +
                        (item.filter === selectedCategory ? " active" : "")
                      }
                      onClick={() => onFilterChange(item.filter)}
                    >
                      {item.name}
                    </button>
                  </li>

                  {key !== projectCategories.length - 1 ? (
                    <li>
                      <span className="btn btn-link">-</span>
                    </li>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))}
          </ul>
        </div>
      )}
      <div className={"gallery-item-wrapper"}>
        <div className="gallery-items">
          {items &&
            items.map((item) => {
              return (
                <Link
                  to={`/projects/${item.slug}`}
                  className={"gallery-item"}
                  key={item.title}
                >
                  {item.coverImage ? (
                    <GatsbyImage
                      image={getImage(item.coverImage.gatsbyImageData)}
                      // TODO: fix alt text
                      alt="text comes here"
                    />
                  ) : (
                    // Placeholder Image when no cover image set
                    <div className="img object-fit">
                      <div className="object-fit-cover">
                        <img
                          src={PlaceholderImage}
                          alt="Just an orange rectangle as a placeholder"
                        />
                      </div>
                    </div>
                  )}

                  <div className="gallery-hover">
                    <div className="gallery-hover-wrapper">
                      <h3>{item.title}</h3>
                      <div className="gallery-hover-categories">
                        {item.category &&
                          item.category.map((cat) => (
                            <h6 key={cat.name}>{cat.name}</h6>
                          ))}
                      </div>
                      <span className="btn btn-link border-0 transform-scale-h p-0">
                        Look inside
                        <i className="icon-c icon-arrow-right" />
                      </span>
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
      {props.seeMore ? (
        <div className="text-center no-space">
          <Link
            to="/projects"
            className="btn btn-outline-dark transform-scale-h"
          >
            See all projects
            <i className="icon-c icon-arrow-right" />
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
