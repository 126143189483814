import React from "react";
import { Link } from "gatsby";
import { useGoal } from "gatsby-plugin-fathom";

const PageTitleHome = () => {
  const handleGoal = useGoal("YDH3OE4V");
  return (
    <section id="page-title" className="block">
      <div className="wrapper">
        <div className="row">
          <div className="col position-relative">
            <div className="title">
              {/* <h1 className="h">
                Data<span className="dot">.</span> Viz
                <span className="dot">.</span> Dev
                <span className="dot">.</span>
              </h1> */}

              <div className="title-clone">Data. Viz. Dev.</div>
            </div>

            <div className="spacer p-top-lg">
              <p
                className="p-large w-75 text-wr-bl"
                style={{ color: "#2b2b2b" }}
              >
                Hi, I'm a <mark>freelance Data Visualization Developer</mark>.
              </p>
              <p
                className="p-large w-75  text-wr-bl"
                style={{ color: "#2b2b2b" }}
              >
                I build <mark>custom, interactive data visualizations</mark>{" "}
                with code that turn your data into{" "}
                <mark>engaging experiences.</mark>
              </p>
              <Link to="/contact">
                <button
                  className="btn btn-dark btn-bold transform-scale-h border-0"
                  onClick={handleGoal}
                >
                  Get in touch
                </button>
              </Link>
            </div>
          </div>

          <div className="col col-2 d-none d-sm-block">
            <div className="d-flex">
              <div className="align-self-start w-100">
                <div className="img object-fit">
                  <div className="object-fit-cover">
                    {/* <img
                      src={Photo}
                      className="img-fluid"
                      alt="Kristin Baumann"
                    /> */}
                  </div>
                </div>
              </div>

              <div className="align-self-end p-left-scroll">
                {/* <LinkScroll
                  to="my-projects"
                  title="Scroll"
                  spy={true}
                  smooth={true}
                  duration={900}
                  className="btn btn-link btn-vertical transform-scale-h border-0 p-0 scroll-to-id d-flex"
                  href="#my-projects"
                >
                  <span className="vertical-text">
                    <span>S</span>
                    <span>c</span>
                    <span>r</span>
                    <span>o</span>
                    <span>l</span>
                    <span>l</span>
                  </span>

                  <span className="align-self-end">
                    <i className="icon-c icon-arrow-down" />
                  </span>
                </LinkScroll> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitleHome;
