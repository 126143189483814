import React from "react";
import ClientLogoSpiegel from "../../assets/clientLogos/clientLogo_Spiegel.png";
import ClientLogoBostonUniversity from "../../assets/clientLogos/clientLogo_BostonUniversity.png";
import ClientLogoStripe from "../../assets/clientLogos/clientLogo_Stripe.png";
import ClientLogoKIT from "../../assets/clientLogos/clientLogo_KIT.png";
import ClientLogoGoogleTrends from "../../assets/clientLogos/clientLogo_GoogleTrends.png";

const ClientList = () => {
  return (
    <section className="wrapper spacer p-top-xl clientList">
      <p className="headline">Selected Clients</p>
      <div className="logos">
        <img
          src={ClientLogoBostonUniversity}
          alt="Kristin Baumann's client Boston University"
        />
        <img
          src={ClientLogoStripe}
          alt="Kristin Baumann's client Stripe company"
        />
        <img
          src={ClientLogoGoogleTrends}
          alt="Kristin Baumann's client Google trends"
        />
        <img
          src={ClientLogoKIT}
          className="hideOnTablet"
          alt="Kristin Baumann's client Karlsruhe Institut für Technologie KIT university"
        />
        <img
          src={ClientLogoSpiegel}
          alt="Kristin Baumann's client Spiegel media company"
        />
      </div>
    </section>
  );
};

export default ClientList;
